import { defineStore } from 'pinia';
import types from '@/types';
import AppService from '@/services/AppService';
import RequestService from '@/services/RequestService';

const useAppService = new AppService();
const useRequestService = new RequestService();

export const useAppStore = defineStore('app', {
  state: () => ({
    notification: {
      title: null,
      message: null,
      color: 'info',
      show: false,
      timex: 10000,
    },
    customerMerchantInfo: {
      sapCustomer: null,
      sapCustomerFiscal: null,
      sapCustomerName: null,

      merchantNumber: null,
      merchantName: null,
      advancePayments: [],
      items: [],
    },
    exchangeRate: 0,
  }),
  actions: {
    [types.CLOSE_NOTIFICATION]() {
      this.notification.show = false;
    },
    [types.SHOW_NOTIFICATION]({
      title, message, timex = 10000, type = 'success',
    }) {
      this[types.CLOSE_NOTIFICATION]();
      this.notification.title = title;
      this.notification.message = message;
      this.notification.show = true;
      this.notification.timex = timex;
      this.notification.type = type;
    },
    async [types.GET_FILE_CONFIG]() {
      return useAppService.getFileConfig();
    },
    async [types.SETUP_APP]() {
      this.customerMerchantInfo.sapCustomer = import.meta.env.VITE_SAP_CUSTOMER;
      this.customerMerchantInfo.sapCustomerFiscal = import.meta.env.VITE_SAP_FISCAL_CUSTOMER;
      this.customerMerchantInfo.merchantNumber = import.meta.env.VITE_MERCHANT_NUMBER;
      this.customerMerchantInfo.merchantName = import.meta.env.VITE_MERCHANT_NAME;

      const exchangeData = await useRequestService.get_exchange_rate();
      this.exchangeRate = exchangeData.rate;
    },
    async [types.GET_CONTRACTOR_MATERIAL](id) {
      return useAppService.getContractorMaterial(id);
    },
  },
});
