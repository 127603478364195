<style scoped lang="sass">
  @use '../../../../styles/variables'
</style>

<template>
  <v-container>
      <router-link :to="{ path: `/request/${requestId}/contractors` }">
      <v-btn class="use-main-color-outline my-4" variant="outlined">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t('Volver atrás') }}
      </v-btn>
    </router-link>
    <v-card-text class="mt-5">
        <v-row justify="center" align="center" style="">
          <v-card
            elevation="3"
            :class="$vuetify.display.mdAndUp
            ? 'w-100 d-inline-flex w-100'
            : 'w-100 w-100'">

            <v-col
              v-show="contentLoading"
              cols="12"
              class="px-4"
              style="place-content: center">
              <v-progress-circular
                style="position: relative; left:50%; transform: translate(-50%)"
                indeterminate />
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" class="px-4 ">
              <checkout-details :data="details" />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" class="px-10 bg-grey-lighten-5 pb-10 pt-16">
              <checkout-form
                :data="details"
                :paymentAttemptLoaded="paymentAttemptLoaded"
                :loadPaymentAttempt="loadPaymentAttempt"
                :showModalPaymentConfirmed="showModalPaymentConfirmed"
                :request="requestHeadById"
              />
            </v-col>
          </v-card>
        </v-row>
    </v-card-text>

    <modal-payment-confirmed
      :showModalPaymentConfirmed="showModalPaymentConfirmed"
    />

  </v-container>
</template>

<script setup>

import {
  onMounted,
  ref,
  onUnmounted,
  reactive,
} from 'vue';
import { storeToRefs } from 'pinia';

import { usePaymentStore } from '@/stores/payment.store';
import { useAppStore } from '@/stores/app';
import useRequestStore from '@/stores/request.store';

import CheckoutDetails from '@/components/CheckoutDetails.vue';
import CheckoutForm from '@/components/forms/requests/CheckoutForm.vue';
import ModalPaymentConfirmed from '@/components/modals/ModalPaymentConfirmed.vue';
import useAuthStore from '@/stores/auth.store';
import types from '@/types';
import constants from '@/constants';
import { useRoute, useRouter } from 'vue-router';
import useContractorStore from '@/stores/contractor.store';

definePage({
  name: 'CheckoutPage',
  meta: {
    requiresAuth: true,
    isAuthPage: false,
  },
});

const paymentStore = usePaymentStore();
const appStore = useAppStore();
const requestStore = useRequestStore();
const authStore = useAuthStore();
const contractorStore = useContractorStore();

const { requestHeadById } = storeToRefs(requestStore);
const { paymentAttempt } = storeToRefs(paymentStore);
const { customerMerchantInfo, exchangeRate } = storeToRefs(appStore);
const { userInfo } = storeToRefs(authStore);
const { contractor } = storeToRefs(contractorStore);
const router = useRouter();
const route = useRoute();

const requestId = route.params.id;
const showModalPaymentConfirmed = ref({ value: false });
const paymentAttemptLoaded = ref(null);
const contentLoading = ref(true);

const details = reactive({
  requestType: '',
  resp: '',
  contractors: [],
  totalPrice: 0,
  totalPriceDop: 0,
});

const paymentAttemptPayload = ref({
  items: [],
});

const validateIfAlreadyPayed = () => {
  if (requestHeadById.value?.factura
    && requestHeadById.value?.doc_pago
    && requestHeadById.value?.doc_contable) {
    router.push(`/request/${requestId}/contractors`);
  }

  if (requestHeadById.value.state === constants.REQUEST_STATUSES.PROCESANDO_PAGO.id) {
    router.push(`/request/${requestId}/contractors`);
  }
};

const loadPaymentAttempt = async () => {
  if (userInfo.value == null || requestHeadById.value == null) {
    await router.push(`/request/${requestId}/contractors`);
  } else {
    validateIfAlreadyPayed();
    const fullName = `${contractor.value.name} ${contractor.value.last_name}`;
    paymentAttemptPayload.value.sap_customer_name = fullName;

    if (contractor.value.tax_credit) {
      paymentAttemptPayload.value.sap_customer = customerMerchantInfo.value.sapCustomerFiscal;
    } else {
      paymentAttemptPayload.value.sap_customer = customerMerchantInfo.value.sapCustomer;
    }

    // Usar bp en caso de que tenga o usar esporadico, esporadico fiscal
    // paymentAttemptPayload.value.sap_customer = (contractor.value.bp !== null
    //   && contractor.value.bp !== '')
    //   ? contractor.value.bp
    //   : customerMerchantInfo.value.sapCustomer;

    paymentAttemptPayload.value.merchant_number = customerMerchantInfo.value.merchantNumber;
    paymentAttemptPayload.value.merchant_name = customerMerchantInfo.value.merchantName;

    paymentAttemptPayload.value.items = [];

    paymentAttempt.value.contractors?.map(async (contractor) => {
      const { price } = contractor.validity_type;
      const tax = (
        contractor.validity_type.price * (contractor.validity_type.tax / 100)
      ).toFixed(2);
      const description = contractor.validity_type.name;
      const amount = (parseFloat(price) + parseFloat(tax)).toFixed(2);

      paymentAttemptPayload.value.items.push({
        location: 'Portal Contratistas',
        number: contractor.validity_type.material.replace(/^0+/, ''),
        description,
        position: contractor.id,
        amount_dop: (amount * parseFloat(exchangeRate.value)).toFixed(2),
        exchange_rate: parseFloat(exchangeRate.value).toFixed(2),
        amount,
        tax,
        tax_dop: (tax * parseFloat(exchangeRate.value)).toFixed(2),
        currency: 'USD',
      });
    });

    paymentAttemptLoaded.value = await paymentStore[types.CREATE_PAYMENT_ATTEMPT](
      paymentAttemptPayload.value,
    );
    details.totalPrice = parseFloat(paymentAttemptLoaded.value.total / exchangeRate.value);
    details.totalPriceDop = parseFloat(paymentAttemptLoaded.value.total);
    details.requestType = requestHeadById.value.request_type.name;
    details.resp = userInfo.value.full_name;
    details.contractors = paymentAttempt.value.contractors;
    contentLoading.value = false;
  }
};

const clearPaymentAttempt = () => {
  contentLoading.value = true;
  paymentAttemptPayload.value = {};
  details.requestType = '';
  details.resp = '';
  details.contractors = [];
  details.totalPrice = 0;
  details.totalPriceDop = 0;
  paymentAttemptPayload.value.items = [];
  paymentAttempt.value.contractors = [];
};

onMounted(() => {
  loadPaymentAttempt();
});

onUnmounted(() => {
  clearPaymentAttempt();
});

</script>
