import constants from '@/constants';
import BaseService from './BaseService';

const resource = 'api/v3/sap/?url=/';

class RequestService extends BaseService {
  constructor() {
    super({ resource });
  }

  async getRequests({ filters, id }) {
    try {
      const requestId = id === undefined ? '' : `${id}/`;
      const requestType = (filters?.request_type === undefined) ? null : filters.request_type;
      const status = (filters?.status === undefined) ? null : filters.status;
      const action = `api_scc_cc/request/${requestId}?type=${requestType}&status=${status}`;
      const response = await this.http.get(this.resource + action);
      return response.data;
    } catch (e) {

    }
  }

  async getRequestTypes(onlyRegister) {
    try {
      const action = 'api_scc_cc/request-type/';
      const response = await this.http.get(this.resource + action);

      if (onlyRegister) {
        response.data = response.data.filter((type) => (
          type.id === constants.REQUEST_TYPES.CONTRATISTA.id
        ));
      } else if (onlyRegister === false) {
        response.data = response.data.filter((type) => (
          type.id !== constants.REQUEST_TYPES.CONTRATISTA.id
        ));
      }

      return response.data;
    } catch (err) {

    }
  }

  async getRequestStatuses() {
    try {
      const action = 'api_scc_cc/status/';
      const response = await this.http.get(this.resource + action);
      const statuses = response.data.filter((status) => status.solicitud === 'X');
      return statuses;
    } catch (err) {

    }
  }

  async getRequestIdentificationTypes(onlyIdTypes, includeRnc) {
    try {
      const action = 'api_scc_cc/document-type/';
      const response = await this.http.get(this.resource + action);
      if (includeRnc && onlyIdTypes) {
        response.data = response.data.filter((type) => type.for_request === 'X');
      } else if (onlyIdTypes) {
        response.data = response.data.filter((type) => type.for_request === 'X' && type.id
          !== constants.REQUEST_IDENTIFICATION_TYPES.RNC.id);
      }
      return response.data;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async createRequest(data) {
    const newRequest = {
      address: data.address,
      comment: data.comment,
      email: data.email,
      ext_rej_reason: '',
      birthday: data.birthday,
      passport_exp_date: data.passport_exp_date,
      comprobante_fiscal: data.comprobante_fiscal,
      nationality: {
        id: data.nationality.id ? data.nationality.id : data.nationality.code,
      },
      id: '',
      identification: data.identification,
      identification_type: {
        id: data.identification_type.id,
        name: data.identification_type.name,
      },
      int_rej_reason: '',
      name: data.name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      request_type: {
        id: data.request_type.id,
        name: data.request_type.name,
      },
      state: {
        id: constants.REQUEST_STATUSES.PENDIENTE_PAGO.id,
        name: constants.REQUEST_STATUSES.PENDIENTE_PAGO.name,
      },
      tax_credit: data.tax_credit,
    };

    const action = 'api_scc_cc/request/';
    return this.http.post(this.resource + action, newRequest);
  }

  async updateRequest(id, data, editContractor) {
    let stateUpdate = {};
    if (editContractor) {
      stateUpdate = {
        id: constants.REQUEST_STATUSES.PENDIENTE_PAGO.id,
        name: constants.REQUEST_STATUSES.PENDIENTE_PAGO.name,
      };
    } else {
      stateUpdate = {
        id: constants.REQUEST_STATUSES.VALIDACION_PENDIENTE.id,
        name: constants.REQUEST_STATUSES.VALIDACION_PENDIENTE.name,
      };
    }
    const request = {
      address: data.address,
      comment: data.comment,
      email: data.email,
      ext_rej_reason: data.ext_rej_reason,
      birthday: data.birthday,
      passport_exp_date: data.passport_exp_date,
      factura: data.factura,
      pedido: data.pedido,
      doc_pago: data.doc_pago,
      doc_contable: data.doc_contable,
      nationality: {
        id: data.nationality.id ? data.nationality.id : data.nationality.code,
      },
      id: data.id,
      identification: data.identification,
      identification_type: {
        id: data.identification_type.id,
        name: data.identification_type.name,
      },
      int_rej_reason: data.int_rej_reason,
      name: data.name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      request_type: {
        id: data.request_type.id,
        name: data.request_type.name,
      },
      state: stateUpdate,
      tax_credit: data.tax_credit,
      bp: data.bp,
      bp_new: data.bp_new,
    };

    const action = `api_scc_cc/request/${id}/`;
    return this.http.put(this.resource + action, request);
  }

  async setDocumentRequest(request, files) {
    files[0].forEach((file) => {
      try {
        const data = {
          documents: [file],
          request: request.data,
        };

        const action = `api_scc_cc/request/${request.data.id}/documents/`;
        return this.http.post(this.resource + action, data);
      } catch (err) {
        throw new Error(`Error al subir el documento: ${err.message}`);
      }
    });
  }

  async clearDocumentsCarnetRequest(id) {
    const action = `api_scc_cc/request/card/${id}/documents/delete/`;
    return this.http.post(this.resource + action, {});
  }

  async setDocumentCarnetRequest(request, files) {
    const form = request.data;
    const newRequest = {
      address: form.address,
      comment: form.comment,
      email: form.email,
      ext_rej_reason: '',
      id: form.id,
      identification: form.identification,
      birthday: form.birthday,
      passport_exp_date: form.passport_exp_date,
      nationality: {
        id: form.nationality.id ? form.nationality.id : form.nationality.code,
      },
      identification_type: {
        id: form.identification_type.id,
        name: form.identification_type.name,
      },
      int_rej_reason: '',
      name: form.full_name,
      phone_number: form.phone_number,
      request_type: form.request_type,
      state: form.state,
      validity_type: form.validity_type,
    };

    const action = `api_scc_cc/request/card/${form.id}/documents/`;

    const requests = Object.values(files).map((file) => {
      const data = {
        documents: [file],
        request: newRequest,
      };
      return this.http.post(this.resource + action, data);
    });

    Promise.all(requests)
      .then(() => {})
      .catch((error) => {
        throw new Error(`Error al subir los documentos: ${error.message}`);
      });
  }

  async getDocumentRequest(id) {
    const action = `api_scc_cc/request/${id}/documents/`;
    return this.http.get(this.resource + action);
  }

  async getDocumentCarnetRequest(id) {
    try {
      const action = `api_scc_cc/request/card/${id}/documents/`;
      return await this.http.get(this.resource + action);
    } catch (e) {
      return {
        status: 500,
        message: e.message,
      };
    }
  }

  async createRequestHead(form) {
    const action = 'api_scc_cc/request/head/';
    return this.http.post(this.resource + action, form);
  }

  async createRequestEmployee(requestHead, form) {
    const newRequest = {
      address: form.address,
      comment: form.comment,
      birthday: (form.birthday === '') ? '0000-00-00' : form.birthday,
      passport_exp_date: (form.passport_exp_date === '') ? '0000-00-00' : form.passport_exp_date,
      nationality: {
        id: form.nationality.id ? form.nationality.id : form.nationality.code,
      },
      email: form.email,
      ext_rej_reason: '',
      id: '',
      identification: form.identification.replaceAll('-', ''),
      identification_type: {
        id: form.identification_type.id,
        name: form.identification_type.name,
      },
      int_rej_reason: '',
      name: form.name,
      last_name: form.last_name,
      phone_number: form.phone_number,
      request_type: {
        id: requestHead.request_type.id,
        name: requestHead.request_type.name,
      },
      state: {
        id: constants.REQUEST_STATUSES.PENDIENTE_PAGO.id,
        name: constants.REQUEST_STATUSES.PENDIENTE_PAGO.name,
      },
      validity_type: form.validity_type,
      request_head: requestHead,
    };

    const action = 'api_scc_cc/request/card/';
    return this.http.post(this.resource + action, newRequest);
  }

  async updateRequestEmployee(requestHead, form, editContractor) {
    let stateUpdate = {};

    if (editContractor) {
      stateUpdate = {
        id: constants.REQUEST_STATUSES.PENDIENTE_PAGO.id,
        name: constants.REQUEST_STATUSES.PENDIENTE_PAGO.name,
      };
    } else {
      stateUpdate = {
        id: constants.REQUEST_STATUSES.VALIDACION_PENDIENTE.id,
        name: constants.REQUEST_STATUSES.VALIDACION_PENDIENTE.name,
      };
    }

    const request = {
      address: form.address,
      comment: form.comment,
      email: form.email,
      birthday: form.birthday,
      passport_exp_date: form.passport_exp_date,
      nationality: {
        id: form.nationality.id ? form.nationality.id : form.nationality.code,
      },
      id: form.id,
      identification: form.identification.replaceAll('-', ''),
      identification_type: {
        id: form.identification_type.id,
        name: form.identification_type.name,
      },
      name: form.name,
      last_name: form.last_name,
      phone_number: form.phone_number,
      request_type: {
        id: requestHead.request_type.id,
        name: requestHead.request_type.name,
      },
      state: stateUpdate,
      validity_type: form.validity_type,
      request_head: requestHead,
    };

    const action = `api_scc_cc/request/card/${form.id}/`;
    return this.http.put(this.resource + action, request);
  }

  async getRequestHead({ filters, id }) {
    const requestId = id === undefined ? '' : `${id}/`;
    const requestType = (filters?.request_type === undefined) ? null : filters.request_type;
    const status = (filters?.status === undefined) ? null : filters.status;
    const action = `api_scc_cc/request/head/${requestId}?type=${requestType}&status=${status}`;
    try {
      const response = await this.http.get(this.resource + action);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async getRequestsFromHead(id) {
    const action = `api_scc_cc/request/card/${id}/`;
    const response = await this.http.get(this.resource + action);
    return response.data;
  }

  async getRequestValidities(id) {
    const action = 'api_scc_cc/validity-type/';
    const response = await this.http.get(this.resource + action);
    if (id === constants.REQUEST_TYPES.EMISION_CARNET.id) {
      return response.data.filter((item) => item.id !== constants.VALIDITIES.REIMPRESION_POR_PERDIDA.id);
    }
    return response.data.filter((item) => item.id === constants.VALIDITIES.REIMPRESION_POR_PERDIDA.id);
  }

  async getNationalities() {
    const action = 'api_real_state/nationality/';
    try {
      const response = await this.http.get(this.resource + action);
      return response.data;
    } catch (err) {

    }
  }

  async cancelRequest(requestId) {
    const action = `api_scc_cc/request/card/${requestId}/cancel/`;
    return this.http.post(this.resource + action);
  }

  async deleteCardRequest(requestId, requestCardId) {
    const action = `api_scc_cc//request/card/${requestId}/${requestCardId}/delete/`;
    return this.http.post(this.resource + action);
  }

  async getCarnetTemporary(headId, requestId) {
    const action = `api_scc_cc/request/card/${headId}/${requestId}/carnet/`;
    return this.http.get(this.resource + action);
  }

  async get_exchange_rate() {
    try {
      const action = 'api_portal_clie/dolar_exchange?sap-client=300';
      const response = await this.http.get(this.resource + action);
      return response.data;
    } catch (err) {
      return err;
    }
  }

  async getDataByIdentification(idNumber) {
    try {
      const action = `api_scc_cc/person/${idNumber}/get-data/`;
      const response = await this.http.get(this.resource + action);
      return response.data;
    } catch (e) {
      return e.response.status;
    }
  }

  async getInvoice(sapCustomer, invoiceId, merchantNumber) {
    try {
      const action = `api_portal_clie/invoice/${sapCustomer}/${invoiceId}/invoice-pdf/?merchant_number=${merchantNumber}&`;
      const response = await this.http.get(this.resource + action);
      return response.data;
    } catch (e) {
      return e.response.status;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getAccessAreas() {
    try {
      const action = 'api_svi/project/';
      const response = await this.http.get(this.resource + action);
      return response.data;
    } catch (e) {
      return e.response.status;
    }
  }

  async validateDocument(filename, content, typeId) {
    let action = 'api/v1/jim-morrinson-tools/';
    if (typeId === constants.REQUEST_IDENTIFICATION_TYPES.CEDULA.id) {
      action += 'check-cedula/';
    } else {
      action += 'check-passport/';
    }
    try {
      const response = await this.http.post(action, { filename, content });
      return response.data;
    } catch (e) {
      return e.response.status;
    }
  }

  async compareFaces(files) {
    try {
      const action = 'api/v1/jim-morrinson-tools/compare-documents/';
      const response = await this.http.post(action, files);
      return response.data;
    } catch (e) {
      return e.response.status;
    }
  }
}

export default RequestService;
